import { J as cleanValue, E as valueTernary, a as _objectSpread2 } from './index-a7690a33.esm.js';
import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';
import _objectWithoutProperties from '@babel/runtime/helpers/esm/objectWithoutProperties';
import { useMemo, useCallback } from 'react';
import { g as getOptionValue, a as getOptionLabel } from './Select-e6f4af24.esm.js';

var _excluded = ["allowCreateWhileLoading", "createOptionPosition", "formatCreateLabel", "isValidNewOption", "getNewOptionData", "onCreateOption", "options", "onChange"];

var compareOption = function compareOption() {
  var inputValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var option = arguments.length > 1 ? arguments[1] : undefined;
  var accessors = arguments.length > 2 ? arguments[2] : undefined;
  var candidate = String(inputValue).toLowerCase();
  var optionValue = String(accessors.getOptionValue(option)).toLowerCase();
  var optionLabel = String(accessors.getOptionLabel(option)).toLowerCase();
  return optionValue === candidate || optionLabel === candidate;
};

var builtins = {
  formatCreateLabel: function formatCreateLabel(inputValue) {
    return "Create \"".concat(inputValue, "\"");
  },
  isValidNewOption: function isValidNewOption(inputValue, selectValue, selectOptions, accessors) {
    return !(!inputValue || selectValue.some(function (option) {
      return compareOption(inputValue, option, accessors);
    }) || selectOptions.some(function (option) {
      return compareOption(inputValue, option, accessors);
    }));
  },
  getNewOptionData: function getNewOptionData(inputValue, optionLabel) {
    return {
      label: optionLabel,
      value: inputValue,
      __isNew__: true
    };
  }
};
function useCreatable(_ref) {
  var _ref$allowCreateWhile = _ref.allowCreateWhileLoading,
      allowCreateWhileLoading = _ref$allowCreateWhile === void 0 ? false : _ref$allowCreateWhile,
      _ref$createOptionPosi = _ref.createOptionPosition,
      createOptionPosition = _ref$createOptionPosi === void 0 ? 'last' : _ref$createOptionPosi,
      _ref$formatCreateLabe = _ref.formatCreateLabel,
      formatCreateLabel = _ref$formatCreateLabe === void 0 ? builtins.formatCreateLabel : _ref$formatCreateLabe,
      _ref$isValidNewOption = _ref.isValidNewOption,
      isValidNewOption = _ref$isValidNewOption === void 0 ? builtins.isValidNewOption : _ref$isValidNewOption,
      _ref$getNewOptionData = _ref.getNewOptionData,
      getNewOptionData = _ref$getNewOptionData === void 0 ? builtins.getNewOptionData : _ref$getNewOptionData,
      onCreateOption = _ref.onCreateOption,
      _ref$options = _ref.options,
      propsOptions = _ref$options === void 0 ? [] : _ref$options,
      propsOnChange = _ref.onChange,
      restSelectProps = _objectWithoutProperties(_ref, _excluded);

  var _restSelectProps$getO = restSelectProps.getOptionValue,
      getOptionValue$1 = _restSelectProps$getO === void 0 ? getOptionValue : _restSelectProps$getO,
      _restSelectProps$getO2 = restSelectProps.getOptionLabel,
      getOptionLabel$1 = _restSelectProps$getO2 === void 0 ? getOptionLabel : _restSelectProps$getO2,
      inputValue = restSelectProps.inputValue,
      isLoading = restSelectProps.isLoading,
      isMulti = restSelectProps.isMulti,
      value = restSelectProps.value,
      name = restSelectProps.name;
  var newOption = useMemo(function () {
    return isValidNewOption(inputValue, cleanValue(value), propsOptions, {
      getOptionValue: getOptionValue$1,
      getOptionLabel: getOptionLabel$1
    }) ? getNewOptionData(inputValue, formatCreateLabel(inputValue)) : undefined;
  }, [formatCreateLabel, getNewOptionData, getOptionLabel$1, getOptionValue$1, inputValue, isValidNewOption, propsOptions, value]);
  var options = useMemo(function () {
    return (allowCreateWhileLoading || !isLoading) && newOption ? createOptionPosition === 'first' ? [newOption].concat(_toConsumableArray(propsOptions)) : [].concat(_toConsumableArray(propsOptions), [newOption]) : propsOptions;
  }, [allowCreateWhileLoading, createOptionPosition, isLoading, newOption, propsOptions]);
  var onChange = useCallback(function (newValue, actionMeta) {
    if (actionMeta.action !== 'select-option') {
      return propsOnChange(newValue, actionMeta);
    }

    var valueArray = Array.isArray(newValue) ? newValue : [newValue];

    if (valueArray[valueArray.length - 1] === newOption) {
      if (onCreateOption) onCreateOption(inputValue);else {
        var newOptionData = getNewOptionData(inputValue, inputValue);
        var newActionMeta = {
          action: 'create-option',
          name: name,
          option: newOptionData
        };
        propsOnChange(valueTernary(isMulti, [].concat(_toConsumableArray(cleanValue(value)), [newOptionData]), newOptionData), newActionMeta);
      }
      return;
    }

    propsOnChange(newValue, actionMeta);
  }, [getNewOptionData, inputValue, isMulti, name, newOption, onCreateOption, propsOnChange, value]);
  return _objectSpread2(_objectSpread2({}, restSelectProps), {}, {
    options: options,
    onChange: onChange
  });
}

export { useCreatable as u };
