var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.js
var src_exports = {};
__export(src_exports, {
  default: () => src_default
});
module.exports = __toCommonJS(src_exports);

// src/AvMessage.js
var AvMessage = class {
  subscribers = {};
  constructor() {
    this.isEnabled = true;
    this.DEFAULT_EVENT = "avMessage";
    this.DOMAIN = /https?:\/\/([\w-]+\.)?availity\.(com|net)/;
    window.addEventListener("message", this.getEventData);
  }
  enabled(value) {
    if (arguments.length > 0) {
      this.isEnabled = !!value;
    }
    return this.isEnabled;
  }
  getEventData = (event) => {
    if (!this.isEnabled || !event || !event.data || !event.origin || !event.source || event.source === window || !this.isDomain(event.origin)) {
      return;
    }
    let { data } = event;
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch {
      }
    }
    if (typeof data === "string") {
      event = data;
      data = void 0;
    } else {
      event = data && data.event || this.DEFAULT_EVENT;
    }
    this.onMessage(event, data);
  };
  subscribe(event, fn) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = [];
    }
    this.subscribers[event].push(fn);
    return () => {
      this.subscribers[event] = this.subscribers[event].filter((val) => val !== fn);
    };
  }
  unsubscribe(event) {
    delete this.subscribers[event];
  }
  unsubscribeAll() {
    this.subscribers = {};
  }
  onMessage(event, data) {
    if (this.subscribers[event]) {
      for (const fn of this.subscribers[event]) {
        fn(data);
      }
    }
  }
  isDomain(url) {
    return !this.DOMAIN.test(this.domain()) || this.DOMAIN.test(url);
  }
  domain() {
    if (window.location.origin) {
      return window.location.origin;
    }
    if (window.location.hostname) {
      return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;
    }
    return "*";
  }
  send(payload, target = window.top) {
    if (!this.isEnabled || !payload) {
      return;
    }
    try {
      const message = typeof payload === "string" ? payload : JSON.stringify(payload);
      target.postMessage(message, this.domain());
    } catch (error) {
      console.warn("AvMessage.send()", error);
    }
  }
};
var AvMessage_default = AvMessage;

// src/index.js
var src_default = new AvMessage_default();
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {});
