var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.js
var src_exports = {};
__export(src_exports, {
  AvCodeSelect: () => AvCodeSelect,
  AvNavigationSelect: () => AvNavigationSelect,
  AvOrganizationSelect: () => AvOrganizationSelect_default,
  AvPayerSelect: () => AvPayerSelect_default,
  AvPermissionSelect: () => AvPermissionSelect,
  AvProviderSelect: () => AvProviderSelect,
  AvRegionSelect: () => AvRegionSelect_default,
  AvUserSelect: () => AvUserSelect,
  ResourceSelect: () => ResourceSelect_default,
  SelectField: () => SelectField_default,
  default: () => Select_default
});
module.exports = __toCommonJS(src_exports);

// src/Select.js
var import_react = __toESM(require("react"));
var import_prop_types = __toESM(require("prop-types"));
var import_classnames = __toESM(require("classnames"));
var import_formik = require("formik");
var import_react_select = __toESM(require("react-select"));
var import_creatable = __toESM(require("react-select/creatable"));
var import_react_select_async_paginate = require("react-select-async-paginate");
var import_get = __toESM(require("lodash/get"));
var import_has = __toESM(require("lodash/has"));
var import_isFunction = __toESM(require("lodash/isFunction"));
var import_isEqual = __toESM(require("lodash/isEqual"));
var { DownChevron, CrossIcon, DropdownIndicator, ClearIndicator, Option, MultiValueRemove } = import_react_select.components;
var CreatableAsyncPaginate = (0, import_react_select_async_paginate.withAsyncPaginate)(import_creatable.default);
var components = {
  DropdownIndicator: (props) => /* @__PURE__ */ import_react.default.createElement(DropdownIndicator, __spreadValues({}, props), /* @__PURE__ */ import_react.default.createElement(DownChevron, null), /* @__PURE__ */ import_react.default.createElement("span", {
    className: "sr-only"
  }, "Toggle Select Options")),
  ClearIndicator: (props) => {
    const innerProps = __spreadProps(__spreadValues({}, props.innerProps), {
      role: "button",
      "aria-hidden": false
    });
    return /* @__PURE__ */ import_react.default.createElement(ClearIndicator, __spreadProps(__spreadValues({}, props), {
      innerProps
    }), /* @__PURE__ */ import_react.default.createElement(CrossIcon, null), /* @__PURE__ */ import_react.default.createElement("span", {
      className: "sr-only"
    }, "Clear all selections"));
  },
  Option: (props) => {
    const innerProps = __spreadProps(__spreadValues({}, props.innerProps), {
      role: "option",
      "aria-selected": props.isSelected,
      name: props.innerProps.id
    });
    return /* @__PURE__ */ import_react.default.createElement(Option, __spreadProps(__spreadValues({}, props), {
      innerProps
    }));
  },
  MultiValueRemove: (props) => {
    const innerProps = __spreadProps(__spreadValues({}, props.innerProps), {
      "aria-hidden": false
    });
    return /* @__PURE__ */ import_react.default.createElement(MultiValueRemove, __spreadProps(__spreadValues({}, props), {
      innerProps
    }));
  }
};
var createOption = (label, labelKey = "label", valueKey = "value") => ({
  [labelKey]: label,
  [valueKey]: label.toLowerCase().replace(/\W/g, "")
});
var areValueAndOptionValueEqual = (value, optionValue) => (0, import_isEqual.default)(value, optionValue);
var selectAllOption = {
  label: "Select all",
  value: "*"
};
var validateSelectAllOptions = (options) => {
  const filtered = options.filter((option) => option.value === selectAllOption.value);
  if (filtered.length > 0) {
    console.warn(`An option contains the value: ${selectAllOption.value}. This value is used by the Select All option.`);
  }
};
var Select = (_a) => {
  var _b = _a, {
    name,
    validate,
    className,
    options,
    selectRef,
    styles,
    maxLength,
    onChange: onChangeCallback,
    autofill,
    creatable,
    allowSelectAll,
    waitUntilFocused,
    helpMessage,
    feedback,
    placeholder,
    components: componentOverrides
  } = _b, attributes = __objRest(_b, [
    "name",
    "validate",
    "className",
    "options",
    "selectRef",
    "styles",
    "maxLength",
    "onChange",
    "autofill",
    "creatable",
    "allowSelectAll",
    "waitUntilFocused",
    "helpMessage",
    "feedback",
    "placeholder",
    "components"
  ]);
  const [_a2, ..._b2] = (0, import_formik.useField)({
    name,
    validate
  }), _c = _a2, { onChange, value: fieldValue } = _c, field = __objRest(_c, ["onChange", "value"]), [{ touched, error: fieldError }] = _b2;
  const { values, setFieldValue, initialValues } = (0, import_formik.useFormikContext)();
  const [newOptions, setNewOptions] = (0, import_react.useState)([]);
  let _cacheUniq = attributes.cacheUniq;
  if (!Array.isArray(_cacheUniq)) {
    _cacheUniq = [_cacheUniq];
  }
  placeholder = /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, placeholder || "Select...", /* @__PURE__ */ import_react.default.createElement("span", {
    className: "sr-only"
  }, touched && fieldError || null, " ", helpMessage || null));
  const getOptionLabel = (option) => {
    if (option.__isNew__) {
      return option.label;
    }
    return option[(0, import_get.default)(attributes, "labelKey", "label")];
  };
  const getValueKey = (attrs = attributes) => (0, import_get.default)(attrs, "valueKey", "value");
  const getOptionValue = (option) => attributes.raw && !attributes.valueKey ? option : (0, import_get.default)(option, getValueKey(attributes), option);
  const prepValue = (value, digIfMulti = true) => {
    if (attributes.isMulti && digIfMulti && Array.isArray(value)) {
      return value.map((val) => prepValue(val));
    }
    if (attributes.raw || attributes.loadOptions && !attributes.valueKey) {
      return value;
    }
    const valueKey = getValueKey();
    return (0, import_get.default)(value, valueKey, value);
  };
  const findOptionFromValue = (value, options2) => {
    if (Array.isArray(options2)) {
      const flattened = [...options2, ...newOptions].reduce((prev, current) => {
        if (current.type === "group") {
          return prev.concat(current.options);
        }
        return prev.concat(current);
      }, []);
      return flattened.filter((o) => areValueAndOptionValueEqual(value, getOptionValue(o)))[0];
    }
    return null;
  };
  const getViewValue = () => {
    if (attributes.raw || attributes.loadOptions || !options)
      return fieldValue;
    if (attributes.isMulti && Array.isArray(fieldValue)) {
      return fieldValue.map((value) => findOptionFromValue(value, options) || value);
    }
    return findOptionFromValue(fieldValue, options) || fieldValue;
  };
  let Tag = attributes.loadOptions ? import_react_select_async_paginate.AsyncPaginate : import_react_select.default;
  if (creatable) {
    Tag = attributes.loadOptions ? CreatableAsyncPaginate : import_creatable.default;
  }
  if (!attributes.inputId) {
    attributes.inputId = name;
  }
  const onChangeHandler = async (newValue) => {
    if (newValue && newValue.length > 0 && newValue[newValue.length - 1].value === selectAllOption.value) {
      newValue = options;
    }
    const newVal = prepValue(newValue);
    const isOverMax = maxLength && attributes.isMulti && newValue && newValue.length > maxLength;
    if (isOverMax)
      return;
    const valuesToSet = { [name]: true };
    await setFieldValue(name, newVal);
    const shouldAutofill = autofill && !attributes.isMulti && newValue && typeof newVal === "object";
    if (shouldAutofill) {
      let formValuesForAutofill = values;
      if (typeof autofill === "object") {
        formValuesForAutofill = Object.keys(autofill).reduce((accum, key) => {
          if ((0, import_has.default)(values, key)) {
            accum[key] = (0, import_get.default)(values, key);
          }
          return accum;
        }, {});
      }
      Object.keys(formValuesForAutofill).filter((fieldName) => fieldName !== name).forEach(async (fieldName) => {
        let rawValue = newValue;
        if (!!newValue.label && !!newValue.value && typeof newValue.value === "object") {
          rawValue = newValue.value;
        }
        let shouldAutofillField = false;
        shouldAutofillField = typeof autofill === "object" ? autofill[fieldName] : (0, import_has.default)(rawValue, fieldName);
        if (shouldAutofillField) {
          let val;
          if (typeof autofill === "object") {
            if ((0, import_isFunction.default)(autofill[fieldName])) {
              val = autofill[fieldName](rawValue);
            } else if (typeof autofill[fieldName] === "string") {
              val = (0, import_get.default)(rawValue, `${autofill[fieldName]}`, initialValues[fieldName]);
            } else {
              val = initialValues[fieldName];
            }
          } else {
            val = (0, import_get.default)(rawValue, fieldName, initialValues[fieldName]);
          }
          valuesToSet[fieldName] = true;
          await setFieldValue(fieldName, val);
        }
      });
    }
    if (onChangeCallback) {
      onChangeCallback(newVal);
    }
  };
  const handleCreate = (value) => {
    const newOpt = createOption(value, (0, import_get.default)(attributes, "labelKey", "label"), (0, import_get.default)(attributes, "valueKey", "value"));
    newOptions.push(newOpt);
    setNewOptions([...newOptions]);
    if (attributes.isMulti) {
      onChangeHandler(Array.isArray(fieldValue) ? fieldValue.concat(newOpt) : [newOpt]);
    } else {
      onChangeHandler(newOpt);
    }
  };
  let selectOptions;
  if (!attributes.loadOptions) {
    if (allowSelectAll && attributes.isMulti) {
      if ([...options, ...newOptions].length > 0 && (values[name] === void 0 || values[name] === null || values[name].length < [...options, ...newOptions].length)) {
        validateSelectAllOptions([...options, ...newOptions]);
        selectOptions = [selectAllOption, ...options, ...newOptions];
      } else {
        selectOptions = [...options, ...newOptions];
      }
    } else {
      selectOptions = [...options, ...newOptions];
    }
  }
  if (attributes.loadOptions && allowSelectAll) {
    console.warn("allowSelectAll is ignored when loadOptions is defined.");
  }
  return /* @__PURE__ */ import_react.default.createElement(Tag, __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, field), {
    onChange: onChangeHandler,
    ref: attributes.loadOptions ? void 0 : selectRef,
    selectRef: attributes.loadOptions ? selectRef : void 0,
    name,
    classNamePrefix: "av",
    role: "listbox",
    onCreateOption: handleCreate,
    className: (0, import_classnames.default)(className, "av-select", touched ? "is-touched" : "is-untouched", fieldError ? "av-invalid" : "av-valid", touched && fieldError && "is-invalid"),
    getOptionLabel,
    getOptionValue,
    closeMenuOnSelect: !attributes.isMulti,
    "aria-invalid": fieldError && touched,
    "aria-errormessage": feedback && fieldValue && fieldError && touched ? `${name}-feedback`.toLowerCase() : "",
    placeholder,
    components: __spreadValues(__spreadValues({}, components), componentOverrides),
    options: selectOptions,
    defaultOptions: waitUntilFocused ? [] : true,
    cacheUniqs: _cacheUniq,
    styles: __spreadProps(__spreadValues({}, styles), {
      placeholder: (provided, state) => {
        if (state.isDisabled) {
          return __spreadProps(__spreadValues({}, provided), {
            borderColor: "#ced4da"
          });
        }
        const showError = touched && fieldError && !state.focused;
        return __spreadProps(__spreadValues({}, provided), {
          color: showError ? "#3D3D3D" : "#666",
          maxWidth: "99%"
        });
      },
      valueContainer: (provided) => __spreadProps(__spreadValues({}, provided), {
        width: "90%"
      }),
      singleValue: (provided) => __spreadProps(__spreadValues({}, provided), {
        color: "#495057"
      }),
      control: (provided, state) => {
        if (state.isDisabled) {
          return __spreadProps(__spreadValues({}, provided), {
            borderRadius: "0.25em",
            borderColor: "inherit",
            backgroundColor: "#e9ecef"
          });
        }
        const showError = touched && fieldError;
        return __spreadProps(__spreadValues({}, provided), {
          borderRadius: "0.25em",
          backgroundColor: "white",
          borderColor: showError ? "#dc3545" : "#555",
          ":hover": {
            borderColor: showError ? "#dc3545" : "#555",
            cursor: "text"
          },
          ":focus-within": {
            borderColor: showError ? "#dc3545" : "#2261b5",
            boxShadow: showError ? "0 0 0 0.2rem rgba(220 53 69 / 25%)" : "0 0 0 0.2rem #2261b5"
          },
          zIndex: state.focused && "3"
        });
      },
      menu: (provided) => __spreadProps(__spreadValues({}, provided), { borderRadius: ".25em" }),
      multiValue: (provided) => __spreadProps(__spreadValues({}, provided), {
        borderRadius: "0.25em",
        width: "auto"
      }),
      input: (provided) => __spreadProps(__spreadValues({}, provided), {
        maxWidth: "99%"
      }),
      dropdownIndicator: (provided, state) => {
        if (state.isDisabled) {
          return provided;
        }
        const showError = touched && fieldError && !state.focused;
        return __spreadProps(__spreadValues({}, provided), {
          pointerEvents: "none",
          color: showError ? "#dc3545" : "#555"
        });
      },
      option: (provided) => __spreadValues({}, provided)
    }),
    theme: (theme) => __spreadProps(__spreadValues({}, theme), {
      borderRadius: 0,
      boxShadow: 0,
      colors: __spreadProps(__spreadValues({}, theme.colors), {
        primary25: "#b8d4fb",
        primary: "#3262af"
      })
    })
  }), attributes), {
    value: getViewValue()
  }));
};
Select.defaultTypes = {
  waitUntilFocused: false
};
Select.propTypes = {
  name: import_prop_types.default.string.isRequired,
  validate: import_prop_types.default.func,
  options: import_prop_types.default.array,
  loadOptions: import_prop_types.default.func,
  raw: import_prop_types.default.bool,
  className: import_prop_types.default.string,
  selectRef: import_prop_types.default.object,
  styles: import_prop_types.default.object,
  maxLength: import_prop_types.default.number,
  onChange: import_prop_types.default.func,
  creatable: import_prop_types.default.bool,
  autofill: import_prop_types.default.oneOfType([import_prop_types.default.bool, import_prop_types.default.object]),
  allowSelectAll: import_prop_types.default.bool,
  waitUntilFocused: import_prop_types.default.bool,
  helpMessage: import_prop_types.default.oneOfType([import_prop_types.default.string, import_prop_types.default.node]),
  feedback: import_prop_types.default.bool,
  placeholder: import_prop_types.default.string,
  components: import_prop_types.default.object
};
components.Option.propTypes = {
  innerProps: import_prop_types.default.object,
  isSelected: import_prop_types.default.bool
};
components.ClearIndicator.propTypes = {
  innerProps: import_prop_types.default.object
};
components.MultiValueRemove.propTypes = {
  innerProps: import_prop_types.default.object
};
var Select_default = Select;

// src/SelectField.js
var import_react2 = __toESM(require("react"));
var import_prop_types2 = __toESM(require("prop-types"));
var import_classnames2 = __toESM(require("classnames"));
var import_reactstrap = require("reactstrap");
var import_form = require("@availity/form");
var SelectField = (_a) => {
  var _b = _a, {
    feedbackClass,
    groupClass,
    label,
    labelClass,
    labelHidden,
    name,
    helpId,
    required,
    helpMessage
  } = _b, attributes = __objRest(_b, [
    "feedbackClass",
    "groupClass",
    "label",
    "labelClass",
    "labelHidden",
    "name",
    "helpId",
    "required",
    "helpMessage"
  ]);
  (0, import_react2.useEffect)(() => {
    if (attributes.id && attributes.id === name) {
      console.warn("Warning: Using an 'id' equivalent to 'name' will result in an orphaned label and break 508 compliance.");
    }
  }, [attributes.id, name]);
  const thisLabel = label ? /* @__PURE__ */ import_react2.default.createElement(import_form.Label, {
    id: `${name}-label`,
    for: name,
    hidden: labelHidden,
    className: labelClass,
    required,
    helpId
  }, label) : null;
  return /* @__PURE__ */ import_react2.default.createElement(import_form.FormGroup, {
    className: groupClass,
    for: name,
    disabled: attributes.disabled
  }, thisLabel, /* @__PURE__ */ import_react2.default.createElement(Select_default, __spreadValues({
    name,
    feedback: true,
    helpMessage,
    required
  }, attributes)), /* @__PURE__ */ import_react2.default.createElement(import_form.Feedback, {
    className: (0, import_classnames2.default)("d-block", feedbackClass),
    name
  }), helpMessage ? /* @__PURE__ */ import_react2.default.createElement(import_reactstrap.FormText, {
    id: `${name}-helpmessage`.toLowerCase()
  }, helpMessage) : null);
};
SelectField.propTypes = {
  name: import_prop_types2.default.string.isRequired,
  feedbackClass: import_prop_types2.default.string,
  groupClass: import_prop_types2.default.string,
  helpId: import_prop_types2.default.string,
  helpMessage: import_prop_types2.default.oneOfType([import_prop_types2.default.string, import_prop_types2.default.node]),
  label: import_prop_types2.default.node,
  labelClass: import_prop_types2.default.string,
  labelHidden: import_prop_types2.default.bool,
  required: import_prop_types2.default.bool
};
var SelectField_default = SelectField;

// src/ResourceSelect.js
var import_react3 = __toESM(require("react"));
var import_prop_types3 = __toESM(require("prop-types"));
var import_qs = __toESM(require("qs"));
var import_get2 = __toESM(require("lodash/get"));
var import_formik2 = require("formik");
var ResourceSelect = (_a) => {
  var _b = _a, {
    name,
    method,
    delay,
    debounceTimeout = delay,
    itemsPerPage,
    watchParams,
    cacheUniq,
    additional,
    resource,
    onPageChange,
    hasMore,
    graphqlConfig,
    minCharsToSearch,
    onFocus,
    waitUntilFocused,
    defaultToOnlyOption,
    defaultToFirstOption,
    shouldSearch,
    additionalPostGetArgs,
    pageAll,
    pageAllSearchBy,
    searchTerm,
    encodeSearchValue
  } = _b, rest = __objRest(_b, [
    "name",
    "method",
    "delay",
    "debounceTimeout",
    "itemsPerPage",
    "watchParams",
    "cacheUniq",
    "additional",
    "resource",
    "onPageChange",
    "hasMore",
    "graphqlConfig",
    "minCharsToSearch",
    "onFocus",
    "waitUntilFocused",
    "defaultToOnlyOption",
    "defaultToFirstOption",
    "shouldSearch",
    "additionalPostGetArgs",
    "pageAll",
    "pageAllSearchBy",
    "searchTerm",
    "encodeSearchValue"
  ]);
  const { setFieldValue } = (0, import_formik2.useFormikContext)();
  let _cacheUniq = cacheUniq;
  const selectRef = (0, import_react3.useRef)();
  const [previousOptions, setPreviousOptions] = (0, import_react3.useState)([]);
  const [numTimesResourceCalled, setNumTimesResourceCalled] = (0, import_react3.useState)(0);
  if (_cacheUniq === void 0 && watchParams) {
    const params = __spreadValues(__spreadValues({
      customerId: rest.customerId
    }, rest.parameters), additionalPostGetArgs || "");
    _cacheUniq = watchParams.map((watchParam) => params[watchParam]);
  }
  if (!Array.isArray(_cacheUniq)) {
    _cacheUniq = [_cacheUniq];
  }
  if (pageAll) {
    debounceTimeout = 0;
  }
  (0, import_react3.useEffect)(() => {
    setNumTimesResourceCalled(0);
  }, [_cacheUniq]);
  const onFocusHandler = (...args) => {
    if (onFocus)
      onFocus(...args);
  };
  const loadOptions = async (...args) => {
    const [inputValue, , additional2 = {}] = args;
    let { page } = additional2;
    const shouldReturnPreviousOptions = inputValue.length > 0 && minCharsToSearch !== void 0 && inputValue.length < minCharsToSearch;
    if (shouldReturnPreviousOptions) {
      return {
        options: previousOptions,
        hasMore: false,
        additional: {
          page,
          limit: itemsPerPage
        }
      };
    }
    let data;
    let params;
    if (graphqlConfig) {
      data = {
        variables: {
          perPage: itemsPerPage,
          filters: {
            [searchTerm]: encodeSearchValue ? encodeURIComponent(inputValue) : inputValue
          }
        }
      };
      if (args.length !== 3) {
        data = typeof rest.parameters === "function" ? rest.parameters(data) : __spreadValues(__spreadValues({}, data), rest.parameters);
      }
      if (graphqlConfig.query) {
        data.query = graphqlConfig.query;
      }
    } else {
      params = {
        [searchTerm]: encodeSearchValue ? encodeURIComponent(inputValue) : inputValue,
        limit: itemsPerPage,
        customerId: rest.customerId
      };
      if (args.length !== 3) {
        params = typeof rest.parameters === "function" ? rest.parameters(params) : __spreadValues(__spreadValues({}, params), rest.parameters);
      }
    }
    if (args.length === 3) {
      if (graphqlConfig) {
        data.variables.page = page;
        if (typeof rest.parameters === "function") {
          data = rest.parameters(data);
        }
      } else {
        params.offset = (page - 1) * itemsPerPage;
        params = typeof rest.parameters === "function" ? rest.parameters(params) : __spreadValues(__spreadValues({}, params), rest.parameters);
      }
    } else {
      page = 1;
    }
    let requiredSatisfied = !rest.requiredParams || rest.requiredParams.length === 0;
    if (!requiredSatisfied) {
      requiredSatisfied = graphqlConfig ? rest.requiredParams.every((param) => (0, import_get2.default)(data, `variables.filters.${param}`)) : rest.requiredParams.every((param) => params[param]);
    }
    let _shouldSearch = shouldSearch;
    if (typeof shouldSearch === "function") {
      _shouldSearch = shouldSearch(...args);
    }
    if (rest.isDisabled || !requiredSatisfied || !_shouldSearch) {
      return {
        options: [],
        hasMore: false
      };
    }
    if (onPageChange)
      onPageChange(inputValue, page);
    if (pageAll && !hasMore && inputValue.length > 0) {
      if (pageAllSearchBy && typeof pageAllSearchBy === "function") {
        const options = await pageAllSearchBy(previousOptions, inputValue);
        return { options, hasMore: false };
      }
      return {
        options: previousOptions.filter((option) => option[rest.labelKey || rest.label].toLowerCase().indexOf(inputValue.toLowerCase()) >= 0),
        hasMore: false
      };
    }
    let fetch;
    if (pageAll && hasMore === void 0 && resource.all) {
      fetch = () => resource.all(data || params);
    } else if (graphqlConfig || method === "POST") {
      fetch = () => resource.post(data || params, __spreadValues({
        headers: {
          "Content-Type": "application/json"
        }
      }, rest.requestConfig));
    } else {
      fetch = () => resource.postGet(import_qs.default.stringify(params, {
        encode: false,
        arrayFormat: "repeat",
        indices: false,
        allowDots: true
      }), __spreadValues({
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }, rest.requestConfig), additionalPostGetArgs);
    }
    return fetch().then(async (resp) => {
      if (!pageAll && !(resp || resp.data) || pageAll && !resp) {
        throw new Error(`API returned an invalid response.`);
      }
      const getResult = rest.getResult || resource.getResult;
      let items = [];
      if (pageAll) {
        items = resp.data ? resp.data : resp;
        if (getResult)
          items = typeof getResult === "function" ? getResult.call(resource, items) : items[getResult];
      } else {
        items = typeof getResult === "function" ? getResult.call(resource, resp.data) : resp.data[getResult];
      }
      if (hasMore === void 0) {
        if (graphqlConfig) {
          hasMore = (data2) => (0, import_get2.default)(data2.data, `${graphqlConfig.type}Pagination.pageInfo.hasNextPage`, false);
        } else if (pageAll) {
          hasMore = false;
        } else {
          hasMore = ({ totalCount, limit, offset }) => totalCount > offset + limit;
        }
      }
      hasMore = typeof hasMore === "function" ? hasMore(resp.data) : hasMore;
      if (!Array.isArray(items)) {
        throw new TypeError(`Expected data to be an array but got \`${typeof items}\`. Use the \`getData\` prop to specify how to get the data from the API response.`);
      }
      setPreviousOptions(items);
      if (!waitUntilFocused && numTimesResourceCalled === 0 && items.length > 0) {
        if (defaultToOnlyOption && items.length === 1 || defaultToFirstOption) {
          await setFieldValue(name, items[0]);
        }
      }
      setNumTimesResourceCalled(numTimesResourceCalled + 1);
      return {
        options: items,
        hasMore,
        additional: __spreadProps(__spreadValues({}, additional2), {
          page: page + 1
        })
      };
    }).catch((error) => {
      if (rest.onError && typeof rest.onError === "function") {
        rest.onError(error);
      }
      return { options: [], hasMore: false };
    });
  };
  const Tag = rest.label ? SelectField_default : Select_default;
  return /* @__PURE__ */ import_react3.default.createElement(Tag, __spreadProps(__spreadValues({
    name,
    selectRef,
    loadOptions,
    defaultOptions: waitUntilFocused ? [] : true,
    pagination: true,
    raw: true,
    debounceTimeout,
    cacheUniqs: _cacheUniq,
    additional: __spreadValues({
      page: 1
    }, additional)
  }, rest), {
    onFocus: onFocusHandler
  }));
};
ResourceSelect.propTypes = {
  name: import_prop_types3.default.string.isRequired,
  requestConfig: import_prop_types3.default.object,
  method: import_prop_types3.default.string,
  resource: import_prop_types3.default.shape({
    postGet: import_prop_types3.default.func,
    post: import_prop_types3.default.func,
    getResult: import_prop_types3.default.oneOfType([import_prop_types3.default.string, import_prop_types3.default.func]),
    all: import_prop_types3.default.func
  }).isRequired,
  getResult: import_prop_types3.default.oneOfType([import_prop_types3.default.string, import_prop_types3.default.func]),
  hasMore: import_prop_types3.default.oneOfType([import_prop_types3.default.bool, import_prop_types3.default.func]),
  delay: import_prop_types3.default.number,
  debounceTimeout: import_prop_types3.default.number,
  label: import_prop_types3.default.node,
  customerId: import_prop_types3.default.string,
  parameters: import_prop_types3.default.oneOfType([import_prop_types3.default.object, import_prop_types3.default.func]),
  itemsPerPage: import_prop_types3.default.number,
  onPageChange: import_prop_types3.default.func,
  isDisabled: import_prop_types3.default.bool,
  requiredParams: import_prop_types3.default.array,
  watchParams: import_prop_types3.default.array,
  minCharsToSearch: import_prop_types3.default.number,
  cacheUniq: import_prop_types3.default.any,
  additional: import_prop_types3.default.object,
  graphqlConfig: import_prop_types3.default.shape({
    type: import_prop_types3.default.string,
    query: import_prop_types3.default.string
  }),
  onFocus: import_prop_types3.default.func,
  waitUntilFocused: import_prop_types3.default.bool,
  defaultToOnlyOption: import_prop_types3.default.bool,
  defaultToFirstOption: import_prop_types3.default.bool,
  shouldSearch: import_prop_types3.default.oneOfType([import_prop_types3.default.bool, import_prop_types3.default.func]),
  additionalPostGetArgs: import_prop_types3.default.object,
  pageAll: import_prop_types3.default.bool,
  pageAllSearchBy: import_prop_types3.default.func,
  onError: import_prop_types3.default.func,
  searchTerm: import_prop_types3.default.string,
  encodeSearchValue: import_prop_types3.default.bool
};
ResourceSelect.defaultProps = {
  delay: 350,
  itemsPerPage: 50,
  waitUntilFocused: false,
  defaultToOnlyOption: false,
  defaultToFirstOption: false,
  shouldSearch: true,
  pageAll: false,
  searchTerm: "q",
  encodeSearchValue: true
};
var ucFirst = (str) => str && str.charAt(0).toUpperCase() + str.slice(1);
ResourceSelect.create = (defaults) => {
  const SpecificResourceSelect = (props) => /* @__PURE__ */ import_react3.default.createElement(ResourceSelect, __spreadValues(__spreadValues({}, defaults), props));
  SpecificResourceSelect.displayName = `${ucFirst(defaults.resource.defaultConfig.name)}Select`;
  return SpecificResourceSelect;
};
var ResourceSelect_default = ResourceSelect;

// src/resources.js
var import_api_axios4 = require("@availity/api-axios");

// src/AvOrganizationSelect.js
var import_react4 = __toESM(require("react"));
var import_prop_types4 = __toESM(require("prop-types"));
var import_api_axios = require("@availity/api-axios");
var OrganizationSelect = ResourceSelect_default.create({
  resource: import_api_axios.avOrganizationsApi,
  labelKey: "name"
});
var AvOrganizationSelect = (_a) => {
  var _b = _a, { name, resourceIds, permissionIds } = _b, props = __objRest(_b, ["name", "resourceIds", "permissionIds"]);
  return /* @__PURE__ */ import_react4.default.createElement(OrganizationSelect, __spreadValues({
    name,
    additionalPostGetArgs: resourceIds || permissionIds ? { resourceIds, permissionIds } : void 0
  }, props));
};
AvOrganizationSelect.propTypes = {
  resourceIds: import_prop_types4.default.oneOfType([
    import_prop_types4.default.arrayOf(import_prop_types4.default.oneOfType([
      import_prop_types4.default.arrayOf(import_prop_types4.default.oneOfType([import_prop_types4.default.string, import_prop_types4.default.number])),
      import_prop_types4.default.string,
      import_prop_types4.default.number
    ])),
    import_prop_types4.default.string,
    import_prop_types4.default.number
  ]),
  permissionIds: import_prop_types4.default.oneOfType([
    import_prop_types4.default.arrayOf(import_prop_types4.default.oneOfType([
      import_prop_types4.default.arrayOf(import_prop_types4.default.oneOfType([import_prop_types4.default.string, import_prop_types4.default.number])),
      import_prop_types4.default.string,
      import_prop_types4.default.number
    ])),
    import_prop_types4.default.string,
    import_prop_types4.default.number
  ]),
  name: import_prop_types4.default.string.isRequired
};
var AvOrganizationSelect_default = AvOrganizationSelect;

// src/AvPayerSelect.js
var import_react5 = __toESM(require("react"));
var import_prop_types5 = __toESM(require("prop-types"));
var import_api_axios2 = __toESM(require("@availity/api-axios"));
var extendedPayersApi = new import_api_axios2.default({ path: "/api/internal", name: "extended-payers" });
extendedPayersApi.all = async (parameters) => extendedPayersApi.query({
  params: { state: parameters.region, transactionType: parameters.tranTypeCode },
  headers: { "X-Availity-Customer-ID": parameters.customerId }
});
var AvPayerSelect = (_a) => {
  var _b = _a, { name, customerId } = _b, props = __objRest(_b, ["name", "customerId"]);
  return /* @__PURE__ */ import_react5.default.createElement(ResourceSelect_default, __spreadValues({
    name,
    labelKey: "payerName",
    resource: extendedPayersApi,
    pageAll: true,
    customerId,
    requiredParams: ["region", "tranTypeCode"],
    watchParams: ["region", "tranTypeCode"]
  }, props));
};
AvPayerSelect.propTypes = {
  customerId: import_prop_types5.default.string.isRequired,
  name: import_prop_types5.default.string.isRequired
};
var AvPayerSelect_default = AvPayerSelect;

// src/AvRegionSelect.js
var import_react6 = __toESM(require("react"));
var import_prop_types6 = __toESM(require("prop-types"));
var import_formik3 = require("formik");
var import_api_axios3 = require("@availity/api-axios");
var RegionSelect = ResourceSelect_default.create({
  resource: import_api_axios3.avRegionsApi,
  labelKey: "value",
  valueKey: "id"
});
var searchBy = (prevOptions, inputValue) => prevOptions.filter((option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 || option.id.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0);
var AvRegionSelect = (_a) => {
  var _b = _a, { defaultToCurrentRegion, name } = _b, props = __objRest(_b, ["defaultToCurrentRegion", "name"]);
  const { setFieldValue } = (0, import_formik3.useFormikContext)();
  const defaultRegion = (0, import_react6.useCallback)(async () => {
    if (defaultToCurrentRegion) {
      try {
        const response = await import_api_axios3.avRegionsApi.getCurrentRegion();
        const value = response.data.regions[0];
        setFieldValue(name, value);
      } catch {
        console.warn("AvRegionSelect failed to load the current region");
      }
    }
  }, [defaultToCurrentRegion, setFieldValue, name]);
  (0, import_react6.useEffect)(() => {
    defaultRegion();
  }, [defaultRegion]);
  return /* @__PURE__ */ import_react6.default.createElement(RegionSelect, __spreadValues({
    name,
    pageAll: true,
    pageAllSearchBy: searchBy,
    getResult: (regions) => regions.map((region) => ({ id: region.id, value: region.value }))
  }, props));
};
AvRegionSelect.propTypes = {
  defaultToCurrentRegion: import_prop_types6.default.bool,
  name: import_prop_types6.default.string.isRequired
};
var AvRegionSelect_default = AvRegionSelect;

// src/resources.js
var AvCodeSelect = ResourceSelect_default.create({
  resource: import_api_axios4.avCodesApi,
  valueKey: "code",
  getOptionLabel: (option) => `${option.code} - ${option.value}`,
  requiredParams: ["list"],
  watchParams: ["list"]
});
var AvNavigationSelect = ResourceSelect_default.create({
  resource: import_api_axios4.avNavigationApi,
  labelKey: "name",
  valueKey: "id"
});
var AvPermissionSelect = ResourceSelect_default.create({
  resource: import_api_axios4.avPermissionsApi,
  labelKey: "description",
  valueKey: "id"
});
var AvProviderSelect = ResourceSelect_default.create({
  resource: import_api_axios4.avProvidersApi,
  labelKey: "uiDisplayName",
  requiredParams: ["customerId"],
  watchParams: ["customerId"]
});
var AvUserSelect = ResourceSelect_default.create({
  resource: import_api_axios4.avUserApi,
  getOptionLabel: (option) => `${option.firstName} ${option.lastName} (${option.id}) - ${option.userId}`
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AvCodeSelect,
  AvNavigationSelect,
  AvOrganizationSelect,
  AvPayerSelect,
  AvPermissionSelect,
  AvProviderSelect,
  AvRegionSelect,
  AvUserSelect,
  ResourceSelect,
  SelectField
});
