var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  FieldHelpIcon: () => FieldHelpIcon,
  Help: () => Help,
  constants: () => constants,
  default: () => Help_default,
  triggerFieldHelp: () => triggerFieldHelp,
  useHelp: () => useHelp
});
module.exports = __toCommonJS(src_exports);

// src/Help.tsx
var import_react = __toESM(require("react"));
var import_message_core = __toESM(require("@availity/message-core"));
var import_icon = __toESM(require("@availity/icon"));
var HelpContext = (0, import_react.createContext)(null);
var useHelpContext = () => {
  const ctx = (0, import_react.useContext)(HelpContext);
  if (!ctx)
    throw new Error("You must use useHelpContext inside the HelpContext Provider");
  return ctx;
};
var constants = {
  SET_HELP: "nav:help:set",
  RESET_HELP: "nav:help:reset",
  OPEN_FIELD_HELP: "nav:help:field"
};
var HelpProvider = ({ children }) => {
  const [help, setHelp] = (0, import_react.useState)();
  const addHelp = (0, import_react.useCallback)((newHelp) => {
    setHelp(newHelp);
    import_message_core.default.send(__spreadValues({
      event: constants.SET_HELP
    }, newHelp));
  }, []);
  const removeHelp = (0, import_react.useCallback)((id) => {
    setHelp(void 0);
    import_message_core.default.send({
      event: constants.RESET_HELP,
      id
    });
  }, []);
  const unload = (0, import_react.useCallback)(() => {
    if (help == null ? void 0 : help.id) {
      import_message_core.default.send({
        event: constants.RESET_HELP,
        id: help.id
      });
    }
  }, [help]);
  (0, import_react.useEffect)(() => {
    window.addEventListener("beforeunload", unload);
    return () => {
      window.removeEventListener("beforeunload", unload);
    };
  }, [help, unload]);
  (0, import_react.useEffect)(() => {
    const handleUnMount = () => {
      if (help == null ? void 0 : help.id) {
        import_message_core.default.send({
          event: constants.RESET_HELP,
          id: help.id
        });
      }
    };
    return () => handleUnMount();
  }, [help]);
  return /* @__PURE__ */ import_react.default.createElement(HelpContext.Provider, {
    value: {
      addHelp,
      removeHelp,
      help
    }
  }, children);
};
var useHelp = ({ type, id }) => {
  const help = useHelpContext();
  const { addHelp, removeHelp } = help;
  (0, import_react.useEffect)(() => {
    addHelp({
      type,
      id
    });
    return () => removeHelp(id);
  }, [addHelp, id, removeHelp, type]);
  return help;
};
var Help = ({ type = "vendor", id, children }) => {
  useHelp({ type, id });
  return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, children);
};
var triggerFieldHelp = (id) => {
  import_message_core.default.send({
    event: constants.OPEN_FIELD_HELP,
    id
  });
};
var handleKeyPress = (event, id) => {
  if (event.key === "Enter") {
    triggerFieldHelp(id);
  }
};
var FieldHelpIcon = ({ color = "primary", size = "1x", id, labelId }) => /* @__PURE__ */ import_react.default.createElement(import_icon.default, {
  role: "link",
  "data-testid": "field-help-icon",
  name: "help-circle",
  size,
  color,
  onClick: () => triggerFieldHelp(id),
  tabIndex: 0,
  onKeyPress: (event) => handleKeyPress(event, id),
  "aria-hidden": "false",
  "aria-label": "help",
  "aria-describedby": labelId
});
var Help_default = HelpProvider;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  FieldHelpIcon,
  Help,
  constants,
  triggerFieldHelp,
  useHelp
});
