var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target, mod));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.js
var src_exports = {};
__export(src_exports, {
  Checkbox: () => Checkbox_default,
  CheckboxGroup: () => CheckboxGroup_default,
  CurrencyInput: () => CurrencyInput_default,
  Feedback: () => Feedback_default,
  Field: () => Field_default,
  Form: () => Form_default,
  FormGroup: () => FormGroup_default,
  Input: () => Input_default,
  Label: () => Label_default,
  Radio: () => Radio_default,
  RadioGroup: () => RadioGroup_default,
  RequiredAsterisk: () => RequiredAsterisk,
  RequiredKey: () => RequiredKey
});
module.exports = __toCommonJS(src_exports);

// src/Form.js
var import_react = __toESM(require("react"));
var import_prop_types = __toESM(require("prop-types"));
var import_uuid = require("uuid");
var import_reactstrap = require("reactstrap");
var import_formik = require("formik");
var useFormikFocusFirstInvalidField = (id, focusInvalidField, invalidInputSelectors) => {
  const formik = (0, import_formik.useFormikContext)();
  const [submitCount, setSubmitCount] = (0, import_react.useState)(formik.submitCount);
  (0, import_react.useEffect)(() => {
    if (focusInvalidField && !formik.isValid && formik.submitCount > submitCount) {
      const form = document.getElementById(id);
      const firstInvalidInput = form.querySelector(invalidInputSelectors);
      firstInvalidInput == null ? void 0 : firstInvalidInput.focus();
      setSubmitCount(formik.submitCount);
    }
  }, [id, focusInvalidField, formik.isValid, formik.submitCount, invalidInputSelectors, submitCount, formik.errors]);
};
var FocusableForm = (_a) => {
  var _b = _a, { children, focusProps } = _b, rest = __objRest(_b, ["children", "focusProps"]);
  useFormikFocusFirstInvalidField(...focusProps);
  return /* @__PURE__ */ import_react.default.createElement(import_reactstrap.Form, __spreadValues({}, rest), children);
};
var Form = (_a) => {
  var _b = _a, {
    initialValues,
    enableReinitialize,
    onSubmit,
    initialStatus,
    initialErrors,
    initialTouched,
    onReset,
    validationSchema,
    validate,
    focusInvalidField,
    id,
    innerRef,
    invalidInputSelectors,
    children
  } = _b, rest = __objRest(_b, [
    "initialValues",
    "enableReinitialize",
    "onSubmit",
    "initialStatus",
    "initialErrors",
    "initialTouched",
    "onReset",
    "validationSchema",
    "validate",
    "focusInvalidField",
    "id",
    "innerRef",
    "invalidInputSelectors",
    "children"
  ]);
  const formId = id || (0, import_uuid.v4)();
  const focusProps = [id = formId, focusInvalidField, invalidInputSelectors];
  return /* @__PURE__ */ import_react.default.createElement(import_formik.Formik, {
    initialValues,
    enableReinitialize,
    onSubmit,
    onReset,
    initialStatus,
    initialErrors,
    initialTouched,
    validationSchema,
    validate,
    innerRef
  }, (props) => /* @__PURE__ */ import_react.default.createElement(FocusableForm, __spreadValues({
    "data-testid": "form-container",
    tag: import_formik.Form,
    id: formId,
    focusProps
  }, rest), typeof children === "function" ? children(props) : children));
};
Form.propTypes = {
  children: import_prop_types.default.oneOfType([import_prop_types.default.func, import_prop_types.default.node]),
  enableReinitialize: import_prop_types.default.bool,
  focusInvalidField: import_prop_types.default.bool,
  id: import_prop_types.default.string,
  initialErrors: import_prop_types.default.object,
  initialStatus: import_prop_types.default.any,
  initialTouched: import_prop_types.default.object,
  initialValues: import_prop_types.default.object.isRequired,
  innerRef: import_prop_types.default.any,
  invalidInputSelectors: import_prop_types.default.string,
  onReset: import_prop_types.default.func,
  onSubmit: import_prop_types.default.func,
  validate: import_prop_types.default.func,
  validationSchema: import_prop_types.default.object
};
Form.defaultProps = {
  focusInvalidField: true,
  invalidInputSelectors: 'input[aria-invalid="true"], div.is-invalid input:first-of-type:not([hidden]):not([style*="display:none"]):not([style*="display: none"])',
  onSubmit: () => {
  }
};
FocusableForm.propTypes = {
  children: import_prop_types.default.node,
  focusProps: import_prop_types.default.array
};
var Form_default = Form;

// src/Field.js
var import_react6 = __toESM(require("react"));
var import_prop_types6 = __toESM(require("prop-types"));
var import_reactstrap6 = require("reactstrap");
var import_uuid3 = require("uuid");

// src/Feedback.js
var import_react2 = __toESM(require("react"));
var import_prop_types2 = __toESM(require("prop-types"));
var import_reactstrap2 = require("reactstrap");
var import_formik2 = require("formik");
var Feedback = (_a) => {
  var _b = _a, { name } = _b, rest = __objRest(_b, ["name"]);
  const feedbackId = `${name}-feedback`.toLowerCase();
  return /* @__PURE__ */ import_react2.default.createElement(import_formik2.ErrorMessage, __spreadValues({
    id: feedbackId,
    component: import_reactstrap2.FormFeedback,
    name
  }, rest));
};
Feedback.propTypes = {
  name: import_prop_types2.default.string.isRequired
};
var Feedback_default = Feedback;

// src/FormGroup.js
var import_react3 = __toESM(require("react"));
var import_prop_types3 = __toESM(require("prop-types"));
var import_reactstrap3 = require("reactstrap");
var import_classnames = __toESM(require("classnames"));
var import_formik3 = require("formik");
var FormGroup = (_a) => {
  var _b = _a, { className, for: For } = _b, props = __objRest(_b, ["className", "for"]);
  const [, metadata] = (0, import_formik3.useField)(For);
  const classname = (0, import_classnames.default)(className, metadata.touched && metadata.error && `text-danger`);
  return /* @__PURE__ */ import_react3.default.createElement(import_reactstrap3.FormGroup, __spreadValues({
    className: classname
  }, props));
};
FormGroup.propTypes = {
  className: import_prop_types3.default.string,
  for: import_prop_types3.default.string
};
var FormGroup_default = FormGroup;

// src/Input.js
var import_react4 = __toESM(require("react"));
var import_prop_types4 = __toESM(require("prop-types"));
var import_classnames2 = __toESM(require("classnames"));
var import_reactstrap4 = require("reactstrap");
var import_formik4 = require("formik");
var Input = (_a) => {
  var _b = _a, { tag: Tag, className, onChange: propsOnChange, validate, name, feedback, help, required } = _b, rest = __objRest(_b, ["tag", "className", "onChange", "validate", "name", "feedback", "help", "required"]);
  const [_a2, ..._b2] = (0, import_formik4.useField)({
    name,
    validate
  }), _c = _a2, { onChange } = _c, field = __objRest(_c, ["onChange"]), [metadata] = _b2;
  const classes = (0, import_classnames2.default)(className, metadata.touched ? "is-touched" : "is-untouched", metadata.error ? "av-invalid" : "av-valid", metadata.touched && metadata.error && "is-invalid", rest.type === "checkbox" && metadata.touched && metadata.error && "was-validated");
  const error = !!metadata.touched && !!metadata.error;
  const feedbackId = error && feedback ? `${name}-feedback`.toLowerCase() : "";
  const helpMessageId = help ? ` ${name}-helpmessage`.toLowerCase() : "";
  const extraProps = {};
  if (rest.type === "checkbox") {
    extraProps.checked = !!field.value;
  }
  return /* @__PURE__ */ import_react4.default.createElement(Tag, __spreadValues(__spreadValues(__spreadValues({
    className: classes,
    onChange: (e) => {
      onChange(e);
      if (propsOnChange) {
        propsOnChange(e);
      }
    },
    name,
    invalid: error,
    "aria-describedby": feedbackId + helpMessageId,
    "aria-required": required
  }, field), extraProps), rest));
};
Input.propTypes = {
  className: import_prop_types4.default.string,
  feedback: import_prop_types4.default.bool,
  help: import_prop_types4.default.bool,
  name: import_prop_types4.default.string.isRequired,
  onChange: import_prop_types4.default.func,
  required: import_prop_types4.default.bool,
  tag: import_prop_types4.default.oneOfType([import_prop_types4.default.func, import_prop_types4.default.string]),
  validate: import_prop_types4.default.func
};
Input.defaultProps = {
  required: false,
  tag: import_reactstrap4.Input
};
var Input_default = Input;

// src/Label.js
var import_react5 = __toESM(require("react"));
var import_prop_types5 = __toESM(require("prop-types"));
var import_uuid2 = require("uuid");
var import_reactstrap5 = require("reactstrap");
var import_help = require("@availity/help");
var RequiredAsterisk = () => /* @__PURE__ */ import_react5.default.createElement("strong", {
  className: "text-danger d-inline align-text-top",
  "data-testid": "required-asterisk",
  style: {
    fontSize: "130%",
    lineHeight: "100%"
  }
}, "*");
var RequiredKey = () => /* @__PURE__ */ import_react5.default.createElement("div", null, "Fields marked with an asterisk ", /* @__PURE__ */ import_react5.default.createElement(RequiredAsterisk, null), " are required.");
var Label = (_a) => {
  var _b = _a, { helpId, id, required, children } = _b, attributes = __objRest(_b, ["helpId", "id", "required", "children"]);
  const labelId = id || (0, import_uuid2.v4)();
  const Wrapper = ({ children: children2 }) => {
    if (helpId && (attributes.className || attributes.style)) {
      return /* @__PURE__ */ import_react5.default.createElement("div", {
        className: attributes.className,
        style: attributes.style
      }, children2);
    }
    return /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, null, children2);
  };
  return /* @__PURE__ */ import_react5.default.createElement(Wrapper, null, /* @__PURE__ */ import_react5.default.createElement(import_reactstrap5.Label, __spreadValues({
    id: labelId,
    "data-testid": "label"
  }, attributes), required ? /* @__PURE__ */ import_react5.default.createElement(import_react5.default.Fragment, null, /* @__PURE__ */ import_react5.default.createElement(RequiredAsterisk, null), " ") : null, children), helpId ? /* @__PURE__ */ import_react5.default.createElement(import_help.FieldHelpIcon, {
    labelId,
    id: helpId
  }) : null);
};
Label.propTypes = {
  id: import_prop_types5.default.string,
  helpId: import_prop_types5.default.string,
  required: import_prop_types5.default.bool,
  children: import_prop_types5.default.oneOfType([import_prop_types5.default.func, import_prop_types5.default.node])
};
var Label_default = Label;

// src/Field.js
var colSizes = ["xs", "sm", "md", "lg", "xl"];
var Field = (_a) => {
  var _b = _a, {
    helpMessage,
    helpId,
    required,
    label,
    labelHidden,
    inputClass,
    labelClass,
    name: id,
    size,
    disabled,
    readOnly,
    grid,
    labelAttrs,
    groupAttrs,
    prepend,
    append,
    children
  } = _b, attributes = __objRest(_b, [
    "helpMessage",
    "helpId",
    "required",
    "label",
    "labelHidden",
    "inputClass",
    "labelClass",
    "name",
    "size",
    "disabled",
    "readOnly",
    "grid",
    "labelAttrs",
    "groupAttrs",
    "prepend",
    "append",
    "children"
  ]);
  let row = false;
  const inputId = attributes.id || (0, import_uuid3.v4)();
  const col = {};
  const labelCol = {};
  if (grid) {
    for (const colSize of colSizes) {
      if (grid[colSize]) {
        row = true;
        const sizeNum = Number.parseInt(grid[colSize], 10);
        col[colSize] = sizeNum;
        labelCol[colSize] = 12 - sizeNum;
      }
    }
  }
  let input = /* @__PURE__ */ import_react6.default.createElement(Input_default, __spreadValues({
    name: id,
    id: inputId,
    className: inputClass,
    size,
    required,
    disabled,
    readOnly,
    feedback: true,
    help: !!helpMessage
  }, attributes));
  if (prepend || append) {
    input = /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.InputGroup, null, prepend && /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.InputGroupAddon, {
      addonType: "prepend"
    }, typeof prepend === "string" ? /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.InputGroupText, null, prepend) : prepend), input, append && /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.InputGroupAddon, {
      addonType: "append"
    }, typeof append === "string" ? /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.InputGroupText, null, append) : append), /* @__PURE__ */ import_react6.default.createElement(Feedback_default, {
      name: id
    }));
  }
  const help = helpMessage ? /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.FormText, {
    id: `${id}-helpmessage`.toLowerCase()
  }, helpMessage) : null;
  const feedback = /* @__PURE__ */ import_react6.default.createElement(Feedback_default, {
    name: id
  });
  let inputRow = row ? /* @__PURE__ */ import_react6.default.createElement(import_reactstrap6.Col, __spreadValues({}, col), input, !prepend && !append && /* @__PURE__ */ import_react6.default.createElement(Feedback_default, {
    name: id
  }), help) : input;
  if (children && typeof children === "function") {
    inputRow = children({ input: inputRow, feedback });
  }
  const check = attributes.type === "checkbox";
  return /* @__PURE__ */ import_react6.default.createElement(FormGroup_default, __spreadValues({
    for: id,
    check,
    disabled,
    row
  }, groupAttrs), check && inputRow, label && /* @__PURE__ */ import_react6.default.createElement(Label_default, __spreadValues(__spreadValues({
    id: `${inputId}-label`,
    for: inputId,
    className: labelClass,
    hidden: labelHidden,
    size,
    required: !!required,
    helpId,
    disabled
  }, labelCol), labelAttrs), label), !check && inputRow, !row && !prepend && !append && feedback, !row && help);
};
Field.propTypes = {
  name: import_prop_types6.default.string.isRequired,
  append: import_prop_types6.default.node,
  children: import_prop_types6.default.func,
  disabled: import_prop_types6.default.bool,
  grid: import_prop_types6.default.object,
  groupAttrs: import_prop_types6.default.object,
  helpId: import_prop_types6.default.string,
  helpMessage: import_prop_types6.default.node,
  inputClass: import_prop_types6.default.string,
  label: import_prop_types6.default.node,
  labelAttrs: import_prop_types6.default.object,
  labelClass: import_prop_types6.default.string,
  labelHidden: import_prop_types6.default.bool,
  prepend: import_prop_types6.default.node,
  readOnly: import_prop_types6.default.bool,
  required: import_prop_types6.default.bool,
  size: import_prop_types6.default.string,
  tag: import_prop_types6.default.oneOfType([import_prop_types6.default.func, import_prop_types6.default.string])
};
Field.defaultProps = {
  tag: import_reactstrap6.Input,
  required: false
};
var Field_default = Field;

// src/CheckboxGroup.js
var import_react7 = __toESM(require("react"));
var import_prop_types7 = __toESM(require("prop-types"));
var import_classnames3 = __toESM(require("classnames"));
var import_formik5 = require("formik");
var CheckboxGroupContext = (0, import_react7.createContext)();
var useCheckboxGroup = (name) => {
  const { setFieldValue } = (0, import_formik5.useFormikContext)();
  const _a = (0, import_react7.useContext)(CheckboxGroupContext), { name: groupName, groupOnChange, value = [] } = _a, rest = __objRest(_a, ["name", "groupOnChange", "value"]);
  const toggle = () => {
    const valueArray = [...value];
    const indexOfVal = valueArray.indexOf(name);
    if (indexOfVal === -1) {
      valueArray.push(name);
    } else {
      valueArray.splice(indexOfVal, 1);
    }
    setFieldValue(groupName, valueArray);
    if (groupOnChange) {
      groupOnChange(valueArray);
    }
  };
  return __spreadValues({ toggle, value: value.indexOf(name) > -1 }, rest);
};
var CheckboxGroup = (_a) => {
  var _b = _a, {
    name,
    children,
    onChange: groupOnChange,
    groupClassName,
    label,
    labelClassName,
    required,
    helpId
  } = _b, rest = __objRest(_b, [
    "name",
    "children",
    "onChange",
    "groupClassName",
    "label",
    "labelClassName",
    "required",
    "helpId"
  ]);
  const [field, metadata] = (0, import_formik5.useField)(name);
  const classes = (0, import_classnames3.default)(groupClassName, "form-control border-0 p-0 h-auto", metadata.touched ? "is-touched" : "is-untouched", metadata.touched && metadata.error && "is-invalid");
  let tag = "div";
  let legend = null;
  if (label) {
    tag = "fieldset";
    const legendId = `${name}-legend`.toLowerCase();
    const srRequiredAsterisk = required ? "* " : null;
    const styles = { cursor: "default", lineHeight: "inherit", color: "#000" };
    const labelClasses = (0, import_classnames3.default)("form-inline", labelClassName, !labelClassName && "h4 font-weight-normal");
    legend = /* @__PURE__ */ import_react7.default.createElement(import_react7.default.Fragment, null, /* @__PURE__ */ import_react7.default.createElement("legend", {
      id: legendId,
      className: "sr-only"
    }, srRequiredAsterisk, label), /* @__PURE__ */ import_react7.default.createElement("div", {
      className: labelClasses,
      style: styles
    }, /* @__PURE__ */ import_react7.default.createElement(Label_default, {
      tag: "div",
      "aria-hidden": true,
      helpId,
      required
    }, label)));
  }
  return /* @__PURE__ */ import_react7.default.createElement(CheckboxGroupContext.Provider, {
    value: __spreadProps(__spreadValues({}, field), { groupOnChange, metadata })
  }, /* @__PURE__ */ import_react7.default.createElement(FormGroup_default, __spreadValues({
    tag,
    for: name
  }, rest), legend, /* @__PURE__ */ import_react7.default.createElement("div", {
    className: classes,
    "data-testid": `check-items-${name}`
  }, children), /* @__PURE__ */ import_react7.default.createElement(Feedback_default, {
    name
  })));
};
CheckboxGroup.propTypes = {
  children: import_prop_types7.default.node,
  groupClassName: import_prop_types7.default.string,
  helpId: import_prop_types7.default.string,
  label: import_prop_types7.default.node,
  name: import_prop_types7.default.string.isRequired,
  onChange: import_prop_types7.default.func,
  labelClassName: import_prop_types7.default.string,
  required: import_prop_types7.default.bool
};
var CheckboxGroup_default = CheckboxGroup;

// src/Checkbox.js
var import_react8 = __toESM(require("react"));
var import_prop_types8 = __toESM(require("prop-types"));
var import_reactstrap7 = require("reactstrap");
var import_uuid4 = require("uuid");
var import_classnames4 = __toESM(require("classnames"));
var Checkbox = (_a) => {
  var _b = _a, {
    className,
    groupClassName,
    groupName,
    helpId,
    id,
    inline,
    label,
    value: checkValue
  } = _b, attributes = __objRest(_b, [
    "className",
    "groupClassName",
    "groupName",
    "helpId",
    "id",
    "inline",
    "label",
    "value"
  ]);
  const { value, toggle, metadata } = useCheckboxGroup(checkValue);
  const [inputId] = (0, import_react8.useState)(id || (0, import_uuid4.v4)());
  const classes = (0, import_classnames4.default)(className, metadata.touched ? "is-touched" : "is-untouched", metadata.touched && metadata.error && "is-invalid");
  const errorIndicated = !!metadata.touched && !!metadata.error;
  const groupFeedbackId = errorIndicated && groupName ? `${groupName}-feedback`.toLowerCase() : "";
  const labelId = `${inputId}-label`.toLowerCase();
  return /* @__PURE__ */ import_react8.default.createElement(FormGroup_default, {
    for: inputId,
    className: groupClassName,
    check: true,
    inline,
    disabled: attributes.disabled
  }, /* @__PURE__ */ import_react8.default.createElement(import_reactstrap7.Input, __spreadProps(__spreadValues({
    id: inputId,
    name: inputId,
    className: classes,
    type: "checkbox",
    invalid: errorIndicated,
    "aria-describedby": groupFeedbackId
  }, attributes), {
    value: checkValue,
    checked: value,
    onChange: toggle
  })), /* @__PURE__ */ import_react8.default.createElement(Label_default, {
    check: true,
    id: labelId,
    for: inputId,
    helpId
  }, label));
};
Checkbox.propTypes = {
  className: import_prop_types8.default.string,
  disabled: import_prop_types8.default.bool,
  groupClassName: import_prop_types8.default.string,
  groupName: import_prop_types8.default.string,
  helpId: import_prop_types8.default.string,
  id: import_prop_types8.default.string,
  inline: import_prop_types8.default.bool,
  label: import_prop_types8.default.string,
  value: import_prop_types8.default.oneOfType([import_prop_types8.default.string, import_prop_types8.default.bool, import_prop_types8.default.object])
};
Checkbox.defaultProps = {
  inline: true
};
var Checkbox_default = Checkbox;

// src/RadioGroup.js
var import_react9 = __toESM(require("react"));
var import_prop_types9 = __toESM(require("prop-types"));
var import_classnames5 = __toESM(require("classnames"));
var import_formik6 = require("formik");
var RadioGroupContext = (0, import_react9.createContext)();
var useRadioGroup = (radioValue) => {
  const { setFieldValue } = (0, import_formik6.useFormikContext)();
  const _a = (0, import_react9.useContext)(RadioGroupContext), { name: groupName, value = "", groupOnChange } = _a, rest = __objRest(_a, ["name", "value", "groupOnChange"]);
  const setValue = () => {
    setFieldValue(groupName, radioValue);
    if (groupOnChange) {
      groupOnChange(radioValue);
    }
  };
  return __spreadValues({ groupName, setValue, value: value === radioValue }, rest);
};
var RadioGroup = (_a) => {
  var _b = _a, {
    name,
    children,
    label,
    onChange: groupOnChange,
    groupClassName,
    inline = false,
    helpId,
    labelClassName,
    required
  } = _b, rest = __objRest(_b, [
    "name",
    "children",
    "label",
    "onChange",
    "groupClassName",
    "inline",
    "helpId",
    "labelClassName",
    "required"
  ]);
  const [field, metadata] = (0, import_formik6.useField)(name);
  const classes = (0, import_classnames5.default)(groupClassName, "form-control border-0 p-0 h-auto", metadata.touched ? "is-touched" : "is-untouched", metadata.touched && metadata.error && "is-invalid");
  let tag = "div";
  let legend = null;
  if (label) {
    tag = "fieldset";
    const legendId = `${name}-legend`.toLowerCase();
    const styles = { cursor: "default", lineHeight: "inherit", color: "#000" };
    const labelClasses = (0, import_classnames5.default)("form-inline", labelClassName, !labelClassName && "h4 font-weight-normal");
    legend = /* @__PURE__ */ import_react9.default.createElement(import_react9.default.Fragment, null, /* @__PURE__ */ import_react9.default.createElement("legend", {
      id: legendId,
      className: "sr-only"
    }, required ? "* " : null, label), /* @__PURE__ */ import_react9.default.createElement("div", {
      className: labelClasses,
      style: styles
    }, /* @__PURE__ */ import_react9.default.createElement(Label_default, {
      tag: "div",
      "aria-hidden": true,
      helpId,
      required
    }, label)));
  }
  return /* @__PURE__ */ import_react9.default.createElement(RadioGroupContext.Provider, {
    value: __spreadProps(__spreadValues({}, field), { groupOnChange, metadata, inline })
  }, /* @__PURE__ */ import_react9.default.createElement(FormGroup_default, __spreadValues({
    tag,
    for: name
  }, rest), legend, /* @__PURE__ */ import_react9.default.createElement("div", {
    className: classes,
    "data-testid": `radio-items-${name}`
  }, children), /* @__PURE__ */ import_react9.default.createElement(Feedback_default, {
    name
  })));
};
RadioGroup.propTypes = {
  children: import_prop_types9.default.node,
  groupClassName: import_prop_types9.default.string,
  helpId: import_prop_types9.default.string,
  inline: import_prop_types9.default.bool,
  label: import_prop_types9.default.node,
  name: import_prop_types9.default.string,
  onChange: import_prop_types9.default.func,
  labelClassName: import_prop_types9.default.string,
  required: import_prop_types9.default.bool
};
var RadioGroup_default = RadioGroup;

// src/Radio.js
var import_react10 = __toESM(require("react"));
var import_prop_types10 = __toESM(require("prop-types"));
var import_reactstrap8 = require("reactstrap");
var import_uuid5 = require("uuid");
var import_classnames6 = __toESM(require("classnames"));
var Radio = (_a) => {
  var _b = _a, { label, id, name, value: checkValue, className, groupClassName, children, helpId } = _b, attributes = __objRest(_b, ["label", "id", "name", "value", "className", "groupClassName", "children", "helpId"]);
  const { value, setValue, metadata, inline } = useRadioGroup(checkValue);
  const [inputId] = (0, import_react10.useState)(id || (0, import_uuid5.v4)());
  const classes = (0, import_classnames6.default)(className, metadata.touched ? "is-touched" : "is-untouched", metadata.touched && metadata.error && "is-invalid");
  const errorIndicated = !!metadata.touched && !!metadata.error;
  const feedbackId = errorIndicated && name ? `${name}-feedback`.toLowerCase() : "";
  const labelId = `${inputId}-label`.toLowerCase();
  return /* @__PURE__ */ import_react10.default.createElement(FormGroup_default, {
    for: inputId,
    check: true,
    className: groupClassName,
    inline,
    disabled: attributes.disabled
  }, /* @__PURE__ */ import_react10.default.createElement(import_reactstrap8.Input, __spreadProps(__spreadValues({
    id: inputId,
    name: name || inputId,
    className: classes,
    type: "radio",
    invalid: errorIndicated,
    "aria-describedby": feedbackId
  }, attributes), {
    value: checkValue,
    checked: value,
    onChange: setValue
  })), /* @__PURE__ */ import_react10.default.createElement(Label_default, {
    check: true,
    id: labelId,
    for: inputId,
    helpId
  }, label || children));
};
Radio.propTypes = {
  children: import_prop_types10.default.node,
  className: import_prop_types10.default.string,
  disabled: import_prop_types10.default.bool,
  groupClassName: import_prop_types10.default.string,
  helpId: import_prop_types10.default.string,
  id: import_prop_types10.default.string,
  label: import_prop_types10.default.node,
  name: import_prop_types10.default.string,
  value: import_prop_types10.default.oneOfType([import_prop_types10.default.string, import_prop_types10.default.bool, import_prop_types10.default.object])
};
var Radio_default = Radio;

// src/CurrencyInput.tsx
var import_react11 = __toESM(require("react"));
var import_react_currency_input_field = __toESM(require("react-currency-input-field"));
var import_classnames7 = __toESM(require("classnames"));
var import_formik7 = require("formik");
var CurrencyInput = ({ id, name, value, placeholder, disabled, onValueChanged }) => {
  const { setFieldValue, setFieldTouched } = (0, import_formik7.useFormikContext)();
  const [, metadata] = (0, import_formik7.useField)({
    name
  });
  const classes = (0, import_classnames7.default)(metadata.touched ? "is-touched" : "is-untouched", metadata.error ? "av-invalid" : "av-valid", metadata.touched && metadata.error && "is-invalid", "form-control");
  const formatDecimals = async (value2) => {
    setFieldTouched(name, true);
    if (value2 === "") {
      setFieldValue(name, void 0);
      if (onValueChanged) {
        onValueChanged(void 0);
      }
      return;
    }
    const noCommasValue = value2 == null ? void 0 : value2.replace(/,/g, "");
    const number = Number(noCommasValue);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
    const decimalValue = number.toLocaleString(void 0, options).replace(/,/g, "");
    setFieldValue(name, decimalValue);
    if (onValueChanged) {
      onValueChanged(decimalValue);
    }
  };
  return /* @__PURE__ */ import_react11.default.createElement(import_react11.default.Fragment, null, /* @__PURE__ */ import_react11.default.createElement(import_react_currency_input_field.default, {
    id,
    name,
    className: classes,
    prefix: "$",
    placeholder,
    disabled,
    decimalsLimit: 2,
    value,
    "aria-invalid": !!metadata.error,
    onBlur: async (event) => {
      formatDecimals(event.target.value.replace("$", ""));
    },
    onValueChange: onValueChanged
  }), /* @__PURE__ */ import_react11.default.createElement(Feedback_default, {
    name
  }));
};
var CurrencyInput_default = CurrencyInput;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Checkbox,
  CheckboxGroup,
  CurrencyInput,
  Feedback,
  Field,
  Form,
  FormGroup,
  Input,
  Label,
  Radio,
  RadioGroup,
  RequiredAsterisk,
  RequiredKey
});
